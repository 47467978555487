<template>
	<div class="h-100 dis-flex-com po-r">
		<div class="dis-flex-row al-c txt-c w-100 tab-h bk-co-ff bd-bott" style="z-index: 999;">
			<div class="flx-1 pd-x-15">
				<div class="pd-y-15 po-r" :class="tabsStatus == '0' ? 'selectivity ft-co-39':''" @click="tabsClick('0')">
					评论文章
				</div>
			</div>
			<div class="flx-1 pd-x-15">
				<div class="pd-y-15 po-r" :class="tabsStatus == '1' ? 'selectivity ft-co-39':''" @click="tabsClick('1')">
					新闻列表
				</div>
			</div>
		</div>
		<!-- 评论 -->
		<div class="flx-1" v-show="tabsStatus == '0'">
			<div class="w-100 h-100 dis-flex-com" v-html="htmlBody" v-if="!!dataSourceList">
				<!-- <iframe ref="iframe" class="flx-1 ft-sz-20" style="display: block;" :src="dataSourceList.docurl" frameborder="0"
				 scrolling="yes"></iframe> -->
			</div>
			<div v-else class="w-100 h-100 dis-flex-com ju-c po-r txt-c">
				<div style="padding: 0 120px;">
					<img style="width: 440px;" src="../../../assets/image/bg-xiuxi.png">
					<div class="pd-y-15 ft-sz-18" style="color: #bdbdbd;font-weight: 100;">
						<div>
							太棒了!当前没有新稿件,
						</div>
						<div>
							休息,休息一会
						</div>
					</div>
					<div class="pd-t-15">
						<div class="pd-y-10 txt-c w-100 bk-co-f5" style="color: #bdbdbd;" @click="getPaiDan">点击刷新</div>
					</div>
				</div>
			</div>
			<div v-show="goPinLunStatus == '0'" class="po-f w-100 bk-co-ff" style="bottom: 0;left: 0;">
				<div class="dis-flex-row al-c pd-x-10 bk-co-f5">
					<div class="pd-y-15" @click="topAndBottom('top')" :style="toastStatusXia?'color: #0055ff;':''">
						<van-icon name="arrow-left" />
						上一篇
					</div>
					<!-- <div class="mg-l-10" v-if="!!dataSourceList">
						最小评论:{{dataSourceList.needcommentnum1}}
					</div> -->
					<div class="flx-1 txt-c dis-flex-row ju-c">
						<div class="po-r bk-co-ff pinglun_img bd-r-b50" @click="goPinLun('1')">
							<img class="w-100 h-100" src="../../../assets/image/pinglun.png">
						</div>
					</div>
					<!-- <div class="mg-r-10" v-if="!!dataSourceList">
						最大评论:{{dataSourceList.needcommentnum2}}
					</div> -->
					<div class="pd-y-15" @click="topAndBottom('bottom')" :style="toastStatusXia?'color: #0055ff;':''">
						下一篇
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
			<div v-show="goPinLunStatus == '1'" class="po-f w-100 bk-co-ff" style="bottom: 0;left: 0;">
				<div class="pd-x-15 pd-t-15 pd-b-15 dis-flex-row txt-c bd-bott">
					<van-icon name="cross" @click="goPinLun('0')" />
					<div class="flx-1" style="color: #999999;">
						<div>填写评论</div>
						<div class="ov-fl-h" v-if="!!dataSourceList" style="-webkit-line-clamp: 1;">{{dataSourceList.doctitle}}</div>
					</div>
					<div class="ft-co-39" @click="myPingLun(myPingLunStatus == '0' ? '1':'0')">
						{{myPingLunStatus == '0' ?'我的评论':'返回'}}
					</div>
				</div>
				<div>
					<div v-show="myPingLunStatus == '0'">
						<van-field v-model="value17" rows="5" autosize type="textarea" maxlength="60" placeholder="请输入评论内容" @keyup.enter="searchFile($event)"
						 show-word-limit />
						<div class="pd-x-15 pd-b-10">
							<template v-if="userSubmintStatus == '1'">
								<div class="dis-flex-row">
									<!-- 优评提交 -->
									<!-- <div class="flx-1 mg-l-10" v-bind:style="styleObject" @click="userSubmint('0',isfine)">
										<div class="pd-y-10 txt-c ft-co-ff bd-r-10 bk-co-fd">
										优评提交({{recommendNums}}/{{configNumbers}})
										</div>
									</div> -->
									<div v-bind:class="[recommendNums!=0?'flx-2 mg-l-10':'flx-1']" @click="userSubmint('0','0')">
										<div class="pd-y-10 txt-c ft-co-ff bd-r-10 bk-co-39">
										提交
										</div>
									</div>
								</div>
							</template>
							<template v-else-if="userSubmintStatus == '0'">
								<div class="pd-y-10 txt-c ft-co-ff bd-r-10 bk-co-66" @click="userSubmint('1','0')">
									提交
								</div>
							</template>
						</div>
					</div>
					<div class="commentsBody" @scroll="Scroll1" v-show="myPingLunStatus == '1'">
						<div class="pd-y-10 po-r bd-bott" v-for="(item,index) in dataSource" :key="index">
							<div class="dis-flex-row pd-x-10">
								<img class="img-tou1 bk-co-ff bd-r-10" :src="item.headimgurl" alt="">
								<div class="pd-x-10">
									<div class="ft-sz-18 ft-w-b ft-co-39">{{item.nickname}}</div>
									<div class="ft-sz-14 pd-t-5">{{util.DateFormatter(item.createtime*1000,'yyyy-MM-dd HH:mm')}}</div>
								</div>
								<div class="mg-l-auto ">
									<van-icon style="color: #999999;" name="delete-o" @click="delIcon(item)" />
								</div>
							</div>
							<div class="pd-t-10 ft-co-99 ft-sz-14 commentsText" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">
								<span class="pd-x-15"></span> {{item.commentcontent}}
							</div>
						</div>
						<div class="txt-c ft-co-99 ft-sz-16 pd-y-10" v-if="flag1 && !loginShow">
							没有更多数据了
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 新闻列表 -->
		<div class="flx-1" style="overflow-y: auto;" @scroll="Scroll" v-show="tabsStatus == '1'">
			<div class="pd-x-15" style="box-shadow:0 2px 0 0 #eaeaea;" v-for="(item,index) in dataSourceXin" :key="item.manuscriptid"
			 @click="xinWenList(item)">
				<div class="pd-t-15 dis-flex-row al-c ft-sz-18">
					<div class="mg-r-auto" style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;">
						<span :style="index==0?'color: red;':index==1?'color: #ff5500;':index==2?'color: #0000ff;':'color: #999999;'">{{index+1}}.</span>
						<span v-if="item.isnews==1"><van-icon name="flower-o" color="#ff0000" /></span>
						{{item.doctitle}}
					</div>
					<van-icon color="#d5d5d5" name="arrow" />
				</div>
				<div v-if="item.needcommentnum3 >= item.needcommentnum2" class="dis-flex-row al-c pd-b-10 ft-sz-12 ft-co-99 pd-t-5">
					<div class="mg-r-auto pd-l-10 mg-l-10">
						{{util.DateFormatter(item.createtime*1000,'yyyy-MM-dd HH:mm')}}
					</div>
					<div class="mg-l-15" style="color: #2be61d;">
						已完成
					</div>
				</div>
				<div v-else class="dis-flex-row al-c pd-b-10 ft-sz-12 ft-co-99 pd-t-5">
					<div class="mg-r-auto pd-l-10 mg-l-10">
						{{util.DateFormatter(item.createtime*1000,'yyyy-MM-dd HH:mm')}}
					</div>
					<div>
						通过:{{!!item.needcommentnum4?item.needcommentnum4:0}}
					</div>
					<div class="mg-l-15">
						总评:{{!!item.needcommentnum3?item.needcommentnum3:0}}
					</div>
					<!-- <div class="mg-l-15">
						应评:{{item.needcommentnum2}}
					</div> -->
				</div>
			</div>
			<div class="txt-c ft-co-99 ft-sz-16 pd-y-10" v-if="flag && !loginShow">
				没有更多数据了
			</div>
		</div>
		<!-- 结束工作 -->
		<img class="img_icon po-f" v-show="goPinLunStatus != 1" style="right: 10px;bottom: 35%;" @click="loginClick" src="../../../assets/image/changyue-gz.png">
		<!-- 个人中心 -->
		<img class="img_icon po-f" v-show="goPinLunStatus != 1" style="right: 10px;bottom: 25%;" @click="userHome" src="../../../assets/image/changyue-gr.png">
		<van-overlay :show="loginShow">
		  <div class="w-100 h-100 dis-flex-com ju-c" @click.stop>
			  <div class="dis-flex-row ju-c">
				  <van-loading type="spinner" color="#1989fa" />
			  </div>
		  </div>
		</van-overlay>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import { zmitiConfig } from '../../lib/config'
	// import keywords from '../../lib/keywords.json'
	import axios from 'axios'
	import {
		Toast,
		Dialog 
	} from 'vant';
	var {
		changYueAcions,
		adminGeneral
	} = http
	export default {
		name: 'Comments',
		components: {},
		data() {
			return {
				util:util,
				tabsStatus: '0',
				goPinLunStatus: '0',
				myPingLunStatus: '0',
				dataSourceList: {},
				/*派单数据*/
				value17: '',
				/*评论内容*/
				dataSource: [],
				/*评论列表*/
				dataSourceXin: [],
				/*新闻列表*/
				userData: null,
				/*用户信息*/
				condition: {
					page_index: 0,
					page_size: 10
				},
				condition1: {
					page_index: 0,
					page_size: 10
				},
				isnewsgroup:true,//是否有“时政”评论权限，默认true
				total:0,
				total1:0,
				flag:false,
				flag1:false,
				userSubmintStatus:'1',
				pingLunNum:{},
				loginShow:true,
				historyList:[],/*历史稿件*/
				historyInx:-1,
				toastStatus:true,
				htmlBody:'',
				toastStatusXia:false,
				guanJianval1:[],
				configNumbers:0,//自荐优评数量
				recommendNums:0,//自荐优评剩余数量
				isfine:0,//是否自荐为优评
				styleObject:{
					display:'none'
				}
			}
		},
		watch:{
			recommendNums(newVal,oldVal){
				console.log('newVal-newVal',newVal,'oldVal',oldVal);
				if(newVal>0){
					this.isfine=1;
					this.styleObject.display='block';
				}else{
					this.isfine=0;
					this.styleObject.display='none';
				}
			}
		},
		mounted() {
			var s = this;
			s.userInfo();
			console.log(s.$route.params.manuscriptid)
			if(s.$route.params.manuscriptid != undefined && s.$route.params.manuscriptid != 'undefined'){
				s.xinWenList({
					manuscriptid:s.$route.params.manuscriptid
				})
			}else{
				s.getTheArticle();
			}
			// s.guanJianval1 = keywords.list;

			s.getKeywords();
		},
		methods: {
			getRecommentNums(){//获取某个用户可用自荐优评数量
				let s=this;
				let condition={
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
				    "userid":s.$store.state.user.userid
				}
				changYueAcions.getRecommentNums.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						let recommendNums=0;
						let configNumbers=0;
						if(!Array.isArray(res.config) && !Array.isArray(res.use)){
							configNumbers=res.config.numbers
							recommendNums=res.config.numbers-res.use.usenumbers;
						}else{							
							console.log('没有获取到自荐优评数量')
						}
						console.log('自荐优评可用数量：',recommendNums);
						s.recommendNums=recommendNums;
						s.configNumbers=configNumbers;					
					}
				})
			},
			getKeywords(){
				let s = this;
				let nowDate = new Date();
				let year = nowDate.getFullYear,month = nowDate.getMonth()+1,day = nowDate.getDate(),hours = nowDate.getHours();
				axios.get(zmitiConfig.pageHost+'common/keywords.json?t='+new Date(year+'-'+month+'-'+day+' '+hours+':00:00').getTime(),{})
				.then(function (response) {
					s.guanJianval1 = response.data.list;
				}).catch(function (error) {
					console.log(error);
				});
			},
			tabsClick(val) {
				let s = this;
				s.tabsStatus = val;
				if(val =='1'){
					s.getTheArticleList();
				}else{
					s.condition.page_index = 0;
					s.flag = false;
				}
			},
			getPaiDan() { /*点击刷新*/
				let s = this;
				s.loginShow = true;
				s.getTheArticle();
			},
			goPinLun(val) {
				this.goPinLunStatus = val;
			},
			topAndBottom(val) { /*上一篇/下一篇*/
				let s = this;
				if(val == 'top'){
					if(s.historyInx>0){
						s.loginShow = true;
						s.xinWenList(s.historyList[s.historyInx-1]);
						s.historyInx--
					}else{
						Toast('已经是第一篇文章了')
					}
				}else{
					s.loginShow = true;
					s.getTheArticle();
				}
			},
			xinWenList(item){/*新闻跳转评论*/
				var s = this;
				s.loginShow = true;
				var condition = {
					page_index:0,
					page_size:1
				}
				condition = Object.assign(condition, {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
					"manuscriptid":item.manuscriptid,
					"ischeck":1,
					"status":1
				})
				changYueAcions.getManuscriptList.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						if(s.dataSourceList.manuscriptid != res.list[0].manuscriptid){
							s.historyList.push(s.dataSourceList)
							s.historyInx++
						}
						res.list[0].docurl = res.list[0].docurl + '?t='+new Date().getTime()
						s.dataSourceList = res.list[0];
						s.tabsStatus = '0';
						s.htmlBody = '<iframe ref="iframe" class="flx-1 ft-sz-20" style="display: block;" src="'+s.dataSourceList.docurl+'" frameborder="0"scrolling="yes"></iframe>';
						s.loginShow = false;
					}
				})
			},
			delIcon(item) { /*删除评论*/
				let s = this;
				changYueAcions.delComments.http({
					"info": {
						"companyid": s.$store.state.companyid,
						"productid": s.$store.state.productid,
						"commentid": item.commentid,
						"manuscriptid":item.manuscriptid
					}
				}).then(res => {
					if (res.getret === 0) {
						s.commentList();
					}
					Toast(res.msg)
				})
			},
			userSubmint(val,isfine) { /*提交评论*/
				let s = this;
				if(s.value17.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('评论内容不能为空');
				if(s.userSubmintStatus == '0') return false;
				let randomnum = ""+((Math.random()*70)/8).toFixed(0)+(Math.random()*900+100).toFixed(0);
				var gu = s.util.sensitiveWordsType(s.value17,s.guanJianval1);				
				if(gu.length != 0){
					let flag = false,arr = [],refuseType = false;
					gu.forEach((item)=>{
						if(item.type == 3){
							flag = true;
							arr.push(item.text);
						}
						if(item.type == 4){
							refuseType = true;
						}
					})
					if(flag){
						Toast(arr.join(',') + ' 为敏感字,请删除或修改在提交');
						return  false;
					}
					if(refuseType){
						randomnum = "9527";
					}
				}
				if(!/^[\u4e00-\u9fa5||0-9]/.test(s.value17)){
					randomnum = "9527";
				}
				s.userSubmintStatus = val;
				let newVal = s.value17.split('台独');
				if(newVal.length>1){
					newVal.forEach((item,index)=>{
						if(index == 0){
							if(!(item.endsWith('"') || item.endsWith("'") || item.endsWith('‘')|| item.endsWith('“'))){
								newVal[index] = item+'“';
							}
						}else if((index+1) == newVal.length){
							if(!(item.startsWith('"') || item.startsWith("'") || item.startsWith('’')|| item.startsWith('”'))){
								newVal[index] = '”'+item;
							}
						}else{
							if(!(item.startsWith('"') || item.startsWith("'") || item.startsWith('’')|| item.startsWith('”'))){
								item = '”'+item;
							}
							if(!(item.endsWith('"') || item.endsWith("'") || item.endsWith('‘')|| item.endsWith('“'))){
								item = item+'“';
							}
							newVal[index] = item;
						}
					})
				}
				s.value17 = newVal.join('台独');
				let infor={
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
					"manuscriptid": s.dataSourceList.manuscriptid,
					"title": s.dataSourceList.doctitle,
					"nickname": s.userData.user.realname != '' ? s.userData.user.realname : s.userData.user.username,
					"iscommenttype": 1,
					"headimgurl": s.userData.user.wx_headimgurl != '' ?s.userData.user.wx_headimgurl:'http://file.zmiti.com/index/files/file?p=upload/common/20200320/p8_15846792618773585.jpg',
					"commentcontent": s.value17,
					"websiteid": s.dataSourceList.websiteid,
					"docid":s.dataSourceList.docid,
					"randomnum":Number(randomnum),
					"isfine":isfine
				}
				console.log('提交信息：',infor);
				changYueAcions.commentsSubmint.http({
					"info": infor
				}).then(res => {
					s.userSubmintStatus = '1';
					if (res.getret === 0) {
						s.getRecommentNums();
						s.value17 = '';
						var num = 0 ;
						res.list.forEach((v,k)=>{
							if(v.status != 2 && v.status != 4 && v.status != 5){
								num =  num + (v.num*1)
							}
							if(v.status == 1){
								s.pingLunNum.needcommentnum1 = v.num;
							}
						})
						s.pingLunNum.needcommentnum2 = num;
						s.pingLunNum.needcommentnum3 = res.needcommentnum;
						if(s.toastStatus){
							s.getToast();
						}else{
							if(num >= res.needcommentnum){
								Toast('该文章评论已达最大评论数量,请前往下一篇继续评论');
							}else{
								Toast('评论成功,剩余可评论' + (res.needcommentnum - num)+'条');
							}
						}
					}else if(res.getret === 110){
						Dialog.alert({
						  message: '您暂无法评写时政稿件，请与您的组长联系或选择其他稿件',
						}).then(() => {
							s.isnewsgroup=false;//无评论权限
							//跳转至新闻列表
						 	s.tabsClick('1');
						});
					}else{
						Toast(res.msg);
					}
				})
				
			},
			getToast() { /*最大/最小提示*/
				var s = this;
				changYueAcions.getMaxAndMin.http({
					"condition": {
						"companyid": s.$store.state.companyid,
						"productid": s.$store.state.productid,
					}
				}).then(res => {
					// Toast(res.msg);
					if (res.getret === 0) {
						if(res.status == '1'){
							if(s.pingLunNum.needcommentnum2 >= s.pingLunNum.needcommentnum3){
								Dialog.confirm({
								  title: '温馨提示',
								  message: '该文章评论已达最大评论数量,请前往下一篇继续评论',
								  cancelButtonText:'不在提示',
								}).then(() => {
								  s.getTheArticle();
								}).catch(() => {
									s.toastStatus = false;
								});;
							}else{
								Toast('评论成功,剩余最大可评论' + (s.pingLunNum.needcommentnum3 - s.pingLunNum.needcommentnum2)+'条');
							}
						}else{
							if(s.pingLunNum.needcommentnum2 >= s.dataSourceList.needcommentnum1){
								// Toast('该文章评论已达最少评论数量,您可以评论新的文章或者继续评论此文章');
								// Dialog.confirm({
								//   title: '温馨提示',
								//   message: '该文章评论已达最少评论数量,请前往下一篇继续评论',
								//   cancelButtonText:'不在提示',
								// }).then(() => {
								//   s.getTheArticle();
								// }).catch(() => {
									s.toastStatusXia = true;
								// });;
							}else{
								Toast('评论成功,剩余最少可评论' + (s.dataSourceList.needcommentnum1 - s.pingLunNum.needcommentnum2)+'条');
							}
						}
					}
				})
			},
			userHome() { /*个人中心*/
				this.$router.push('/my')
			},
			getTheArticle() { /*派单*/
				var s = this;
				s.toastStatus = true;
				s.toastStatusXia = false;
				var {
					condition
				} = s;
				condition = Object.assign(condition, {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
				})
				changYueAcions.getTheArticle.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						if(JSON.stringify(res.list) != '{}' && JSON.stringify(res.list) != '[]' && s.dataSourceList.manuscriptid != res.list.manuscriptid){
							s.historyList.push(s.dataSourceList)
							s.historyInx++
						}
						if (JSON.stringify(res.list) != '{}' && JSON.stringify(res.list) != '[]') {
							res.list.docurl = res.list.docurl + '?t='+new Date().getTime();
							s.dataSourceList = res.list;
						} else {
							s.dataSourceList = null;
						}
						s.htmlBody = '<iframe ref="iframe" class="flx-1 ft-sz-20" style="display: block;" src="'+s.dataSourceList.docurl+'" frameborder="0"scrolling="yes"></iframe>';
						s.loginShow = false;
					}
				})
			},
			getTheArticleList() { /*新闻列表*/
				var s = this;
				s.loginShow = true;
				var {
					condition
				} = s;
				var date1 = new Date(new Date().getTime() - 4 * 60 * 60 * 1000)
				var year1 = date1.getFullYear();
				var month1 = date1.getMonth() + 1;
				var day1 = date1.getDate();
				var hours1 = date1.getHours();
				var mine1 = date1.getMinutes();
				var miao1 = date1.getSeconds();
				if (month1 < 10) {
				    month1 = "0" + month1;
				}
				if (day1 < 10) {
				    day1 = "0" + day1;
				}
				if (hours1 < 10) {
				    hours1 = "0" + hours1;
				}
				if (mine1 < 10) {
				    mine1 = "0" + mine1;
				}
				if (miao1 < 10) {
				    miao1 = "0" + miao1;
				}
				var nowDate1 = year1 + "-" + month1 + "-" + day1 +' ' + hours1 + ':' + mine1 +':' + miao1;
				condition = Object.assign(condition, {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
					"poststartdate":nowDate1,
					"ischeck":1,
					"status":1
				})
				changYueAcions.getManuscriptList.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						s.flag = false;
						s.total = res.total;
						var num = 0;
						res.list.forEach((v,k)=>{
							v.commentnum.forEach((l,j)=>{
								if(l.status == 0 || l.status == 1 || l.status == 2){
									num =  num + (l.num*1)
								}
								if(l.status == 1){
									v.needcommentnum4 = l.num
								}
								v.needcommentnum3 = num;
							})
							num = 0;							
						})
						if(res.condition.page_index == 0){
							s.dataSourceXin = res.list
						}else{
							s.dataSourceXin = s.dataSourceXin.concat(res.list)
						}

						/*无“时政”评论权限时过滤“时政新闻”*/
						if(!s.isnewsgroup){
							console.log('过滤前',s.dataSourceXin)
							let isnewsgroupStatus=new Array();
							isnewsgroupStatus=s.dataSourceXin.filter(item=>item.isnews===1)
							console.log('时政新闻：',isnewsgroupStatus);
							/*不在时政组时，仅显示有效新闻*/
							if(isnewsgroupStatus.length>0){
								s.dataSourceXin=s.dataSourceXin.filter(item=>item.isnews===0);
								console.log('过滤后：',s.dataSourceXin)
							}
						}						
						s.loginShow = false;
					}
				})
			},
			commentList() { /*评论查询*/
				var s = this;
				var condition = s.condition1;
				condition = Object.assign(condition, {
					"companyid": s.$store.state.companyid,
					"productid": s.$store.state.productid,
					"manuscriptids": s.dataSourceList.manuscriptid,
					"websiteids": s.dataSourceList.websiteid,
					'userids': s.$store.state.user.userid,
					"status": !!s.$store.state.user.userid ? '0,1,2' : '1'
				})
				changYueAcions.getCommentsList.http({
					condition
				}).then(res => {
					if (res.getret === 0) {
						s.flag1 = false;
						s.total1 = res.total;
						if(res.condition.page_index == 0){
							s.dataSource = res.list
						}else{
							s.dataSource = s.dataSource.concat(res.list)
						}
					}
				})
			},
			myPingLun(val) { /*我的评论*/
				let s = this;
				s.myPingLunStatus = val;
				if(val == '1'){
					s.commentList();
				}else{
					s.condition1.page_index = 0;
					s.flag1 = false;
				}
			},
			getToastStatus() { /*取稿件是按最小值取还是最大值取*/

			},
			loginClick() { /*结束工作*/
				var s = this;
				changYueAcions.listenSingle.http({
					"info": {
						"companyid": s.$store.state.companyid,
						"productid": s.$store.state.productid,
						"isonline": 0,
					},
				}).then(res => {
					if (res.getret === 0) {
						s.$router.go(-1)
					}
				})
			},
			userInfo() { /*用户信息*/
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": s.$store.state.companyid,
					"userid": s.$store.state.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.userData = res.info
						console.log('s.userData',s.userData)
						//s.getRecommentNums(res.info.user_company.ucid);
						s.getRecommentNums();
					}
				})
			},
			Scroll(e){
				let s = this;
				const ele = e.srcElement ? e.srcElement : e.target
				 // 监听滚动到div底部
				if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
				  if (s.flag === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
					s.flag = true;
					if(s.total > s.dataSourceXin.length){
						s.condition.page_index ++
						// s.getTheArticleList();
					}
				  }
				}
			},
			searchFile(event){
				var s = this;
				if (13 == event.keyCode && event.altKey)
				    {
				        s.userSubmint('1','0');
				    }
			},
			Scroll1(e){
				let s = this;
				const ele = e.srcElement ? e.srcElement : e.target
				 // 监听滚动到div底部
				if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) {
				  if (s.flag1 === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
					s.flag1 = true
					if(s.total > s.dataSource.length){
						s.condition1.page_index ++
						s.commentList();
					}
				  }
				}
			}
		}
	}
</script>
<style scoped>
	.selectivity::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -4px;
		width: 100%;
		border-bottom: 2px solid #3390ff;
	}

	.pinglun_img {
		padding: 2px;
		width: 54px;
		height: 54px;
		margin-top: -30px;
	}

	.commentsBody {
		height: 420px;
		overflow-y: auto;
	}

	.commentsText {
		line-height: 24px;
	}

	.img_icon {
		width: 48px;
	}
</style>